<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Pesanan</span>
          </span>
          <div class="text-nowrap order-md-last">
            <a id="shareBtn" class="btn px-0 d-inline-block mr-3" @click="shareData">
              <i class="fa fa-share-alt text-light"></i>
            </a>
            <router-link
                :to="'/jual/pesanan-group/edit/'+state.id"
                class="btn px-0 d-inline-block"
            >
              <i class="fas fa-pencil-alt text-light"></i>
            </router-link>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header" v-if="state.finish">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="'background-image: url('+state.imageBanner+');'"
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.data.name }}</p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <p class="mb-1 small text-muted">Jumlah Pcs / Maksimal</p>
              <p class="mb-0 text-main">{{ state.jumlah }} pcs / {{ state.max }} pcs</p>
            </div>
            <!-- .col -->
            <div class="col-6 text-right text-md-left">
              <p class="mb-1 small text-muted">Status</p>
              <p class="mb-0">
                <span class="badge align-top" :class="state.data.status === 1? 'badge-secondary':'badge-danger'">{{ state.data.status === 1? 'AKTIF':'INAKTIF' }}</span>
              </p>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <hr class="no-side-margin" />

            <form @submit.prevent="searchData">
              <div class="input-group input-group-sm">
                  <div class="input-group mb-2">
                  <input type="text" class="form-control" placeholder="Cari..."
                  v-model="state.search" />
                  <span class="input-group-append">
                      <button type="submit" class="btn btn-outline-main"><i class="fa fa-search"></i
                  ></button>
                  </span>
                  </div>
              </div>
            </form>

          <div class="small text-truncate">
            Daftar Produk untuk Pesanan {{ state.data.name }}:
          </div>

          <div id="resultList" class="row mt-3 mb-5" v-if="state.exist">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3" v-for="pr in state.produk" :key="pr.id">
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="float-left w-25 border-right">
                    <div
                      class="cropped"
                      :style="'background-image: url('+state.url + pr.photo+');'"
                    ></div>
                  </div>
                  <div class="float-right w-75 p-2 pl-3">
                    <router-link
                      :to="'/jual/produk/info/'+pr.id"
                      class="
                        card-title
                        text-main
                        font-weight-bold
                        d-block
                        mb-0
                        mt-1
                        card-link
                        stretched-link
                        text-truncate
                      "
                      >{{ pr.name }}</router-link
                    >
                    <p class="card-text mb-0 text-truncate">{{ pr.jumlah }} pcs</p>
                    <p class="card-text mb-0 text-truncate">
                      <small class="text-muted"
                        ><span class="currency">Harga Modal: Rp. {{ changeMoney(pr.price) }}</span></small
                      >
                    </p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
          <div class="row mt-3 mb-5" v-else>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
              <h5 class="text-center mt-4">Data Kosong!</h5>
            </div>
          </div>
          
          <router-link
            to="/jual/pesanan/tambah"
            id="btn-float"
            class="
              bg-warning
              position-fixed
              rounded-circle
              shadow
              text-dark text-center
            "
            >
            <div class="d-flex justify-content-center mt-3">
            <i class="fas fa-cart-plus" style="font-size: 25px;"></i>
            </div>
          </router-link>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import imageDefault from '../../../assets/img/img.png';
import Share from '../../../components/social_media/Share';

export default {
    setup() {
        const route = useRoute();
        const state = reactive({
            imageDefault: imageDefault,
            imageBanner: '',
            id: '',
            data: {},
            url: process.env.VUE_APP_API_URL,
            produk: {},
            originalProduk: {},
            finish: false,
            search: '',
            exist: false,
            max: '~',
            jumlah: 0,
        })
        const loadData = async () => {
            await axios.get('/api/seller/pesanan/getProduk/'+state.id).then((res) => {
                if(res.data.success){
                    state.data = res.data.data;
                    state.produk = res.data.produk;
                    state.originalProduk = res.data.produk;
                    state.max = state.data.max;
                    let key = Object.keys(state.produk);
                    state.jumlah = res.data.jumlah;

                    if(key.length > 0){
                      state.exist = true;
                    }
                    state.finish = true;
                    //image banner 
                    if(state.data.group){
                      state.imageBanner = state.url + state.data.group.photo
                    }else {
                      state.imageBanner = state.imageDefault;
                    }
                }
            })
        }
        onMounted(() => {
            state.id = route.params.id;
            loadData();
        });
        const changeMoney = (dt) => {
            let cur = Math.ceil(Number(dt));
            let mo = (cur).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            let ret = mo.split(".")[0]; 
            return ret; 
        }
        const searchData = () => {
            let result = [];
            const data = state.originalProduk;
            Object.values(data).find((dt, index) => {
            if(dt.name.match(state.search)){
                result.push(data[index]);
            }
            })
            state.produk = result;
            if(state.produk.length > 0){
              state.exist = true;
            }else {
              state.exist = false;
            }
        }
        const shareData = () => {
          let dt = {
            title: state.data.name,
            url: process.env.VUE_APP_HOME_URL + '/home/qty/'+state.data.key,
            description: '',
          }
          Share.run('whatsapp', dt);
        }
        const back = () => {
          window.history.back();
        }
        return {
            state,
            changeMoney,
            searchData,
            shareData,
            back,
        }
    },
}
</script>